

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {emailPasswordSignIn} from "supertokens-web-js/lib/build/recipe/thirdpartyemailpassword";

export const agencyApi = createApi({
    reducerPath: 'agency',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.moonshoot.inc/' }),
    endpoints: (builder) => ({
        getAgency: builder.query({
            query: (body) => ({
                url:'agency/get',
                method: 'POST',
                body,
            }),
            providesTags: (_) => ["Agencies"],
        }),
        newAgency: builder.mutation({
            query: (body, ...rest) => ({
                url:'agency/new',
                method: 'POST',
                body: {
                    "input": body,
                },
            }),
            invalidatesTags: (_) => ["Agencies"],
        }),
        updateAgency: builder.mutation({
            query: (body, ...rest) => ({
                url:'agency/update',
                method: 'POST',
                body: {
                    "input": body,
                },
            }),
            invalidatesTags: (_) => ["Agencies"],
        }),
        deleteAgency: builder.mutation({
            query: (agency_id, ...rest) => ({
                url:'agency/delete',
                method: 'POST',
                body: {
                    "agency_id": agency_id,
                },
            }),
            invalidatesTags: (_) => ["Agencies"],
        }),
        userInvite: builder.mutation({
            query: (body) => ({
                url:'user/invite',
                method: 'POST',
                body: body,
            }),
        }),
    }),
});



export const { useGetAgencyQuery,
    useUpdateAgencyMutation,
    useNewAgencyMutation,
    useDeleteAgencyMutation,
} = agencyApi;

