import {useEffect} from "react";
import {thirdPartySignInAndUp} from "supertokens-web-js/lib/build/recipe/thirdpartyemailpassword";
import DynamicLoader from "../view/dynamic-loader";


export  async function HandleGoogleCallback() {
    try {
        const response = await thirdPartySignInAndUp();
        console.log("google response",response)

        if (response.status === "OK") {
            console.log(response.user)
            if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
                // sign up successful
            } else {
                // sign in successful
            }
            window.location.assign("/login"); // redirect back to login page
        } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
            console.log("SIGN_IN_UP_NOT_ALLOWED")
            window.location.assign("/login"); // redirect back to login page

            // this can happen due to automatic account linking. Please see our account linking docs
        } else {
            // SuperTokens requires that the third party provider
            // gives an email for the user. If that's not the case, sign up / in
            // will fail.

            // As a hack to solve this, you can override the backend functions to create a fake email for the user.

            window.alert("No email provided by social login. Please use another form of login");
            window.location.assign("/login"); // redirect back to login page
        }
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            console.error(err)
        }
    }
}

 export default function GoogleAuthCallback() {

    /** On Page Intialize call function to checking session and load all api datas */
    useEffect(() => {
        HandleGoogleCallback()
    }, []);


    return (<DynamicLoader name="Login"/>);
 }