import {useDeleteAgencyMutation, useGetAgencyQuery, useNewAgencyMutation} from "../../store/api/agency";
import NewAgency from "./new-agency";
import Heading from "../heading";
import {useState,useEffect} from "react";
import JsonView from '@uiw/react-json-view';
import Modal from "../modal";
import NewAgencyModal from "./new-agency";
import NewAgencyUser from "./new-agency-user";
import Success from "../success";
import DynamicLoader from "../dynamic-loader";
import DefaultAvatar from '../assets/svg/default-avatar.svg';
import UpdateAgencyFactor from "./udpdate-agency-factor";
import Session from "supertokens-web-js/recipe/session";


export default function Agencies() {

    const [showSuccess, setShowSuccess] = useState();
    const [showAgency, setShowAgency] = useState();
    const [inviteAgencyUser, setInviteAgencyUser] = useState();
    const [updateAgencyFactor, setUpdateAgencyFactor] = useState();
    const [newAgency, setNewAgency] = useState(false);
    const { data: agenciesData, error: agenciesError, isLoading: agenciesIsLoading } = useGetAgencyQuery({ });
    const [ deleteAgency] = useDeleteAgencyMutation();

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShowSuccess(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, [showSuccess]);

    console.log(agenciesData, agenciesError, agenciesIsLoading)
    if (agenciesIsLoading) {
        return (<DynamicLoader name="Agencies"/>);
    }


    async function doesSessionExist() {
        if (!await Session.doesSessionExist()) {
            window.location.assign("/login"); // redirect back to login page
        }
    }
     doesSessionExist()


    console.log(agenciesError)


    let agencies = [...agenciesData.agencies].sort((a, b) => {
        // Convert date strings to Date objects
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        // Compare dates to sort in descending order
        return dateB - dateA;
    });

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {showSuccess && <Success title="New agency saved!"/>}
            <header className="sticky top-0 bg-white">
               <Heading title="Agencies" breadcrumbs={[{label:'Agency'},{label:'List'}]}/>
            </header>
            <div className="flex justify-end gap-4">
                    <button type="button" onClick={() => setNewAgency(true)}
                            className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        New Agency
                    </button>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Factor
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Location
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Weekly Rate
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {agencies.map((agency,i) => (
                                <tr key={agency.email}>
                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                        <div className="flex items-center">
                                            <div className="h-11 w-11 flex-shrink-0">
                                                {agency.profile_avatar_url?
                                                    <img className="h-11 w-11 rounded-full" src={agency.profile_avatar_url} alt="" />:
                                                    <img className="h-11 w-11 rounded-full" src={DefaultAvatar} alt="" />
                                                }
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">{agency.name}</div>
                                                <div className="mt-1 text-gray-500">{agency.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        <div className={"mt-1 " + agency.factor && agency.factor>0?"text-black":"text-gray-500"}>{agency.factor?agency.factor*100:0}%</div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        <div className="mt-1 text-gray-500 uppercase">{agency.location}</div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{agency.weekly_rate > 0? agency.weekly_rate.toLocaleString("en-US", { style: "currency", currency: "USD"}):0}</td>
                                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        {agency.onboarding_progress ?'':<button onClick={() => {deleteAgency(agency.agency_id)}} className="text-red-600 hover:text-red-900">
                                            Delete<span className="sr-only">, {agency.name}</span>
                                        </button>}
                                        <button onClick={() => {window.open("https://app.moonshoot.inc/agency/profile/" + agency.agency_id, '_blank');}} className="pl-5 text-indigo-600 hover:text-indigo-900">
                                            Profile<span className="sr-only">, {agency.name}</span>
                                        </button>
                                        <button onClick={() => {setShowAgency(agency.agency_id);}} className="pl-5 text-indigo-600 hover:text-indigo-900">
                                            Data<span className="sr-only">, {agency.name}</span>
                                        </button>
                                        <button onClick={() => {window.open("https://api.moonshoot.inc/impersonate?to=" + agency.email)}}  className="pl-5 text-indigo-600 hover:text-indigo-900">
                                            Impersonate<span className="sr-only">, {agency.name}</span>
                                        </button>
                                        <button onClick={() => {setInviteAgencyUser(agency.agency_id);}}  className="pl-5 text-indigo-600 hover:text-indigo-900">
                                            Invite<span className="sr-only">, {agency.name}</span>
                                        </button>
                                        <button onClick={() => {setUpdateAgencyFactor(agency.agency_id);}}  className="pl-5 text-indigo-600 hover:text-indigo-900">
                                            Factor<span className="sr-only">, {agency.name}</span>
                                        </button>
                                    </td>
                                    <Modal open={showAgency===agency.agency_id} onClose={() => setShowAgency(null)} url={showAgency ? "https://app.moonshoot.inc/agency/profile/" + showAgency:null}><JsonView value={agency}/></Modal>
                                    <Modal title={agency.name} open={inviteAgencyUser===agency.agency_id} onClose={() => setInviteAgencyUser(null)}><NewAgencyUser agency={agency} onClose={() => setInviteAgencyUser(null)}/></Modal>
                                    <Modal title={agency.name} open={updateAgencyFactor===agency.agency_id} onClose={() => setUpdateAgencyFactor(null)}><UpdateAgencyFactor agency={agency} onClose={() => setUpdateAgencyFactor(null)}/></Modal>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal open={newAgency} onClose={() => setNewAgency(false)}><NewAgency onSave={()=> {setShowSuccess(true);setNewAgency(false)}} onClose={() => setNewAgency(false)}/></Modal>
        </div>
    )
}
