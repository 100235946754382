import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import Divider from "./divider";
import ProfileMenu from "./profile-menu";

export default function Heading(props) {
    return (
        <>

        <div className="md:flex md:items-center md:justify-between my-2 py-2 bg-white">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {props.title}
                </h2>
            </div>
            <ProfileMenu/>
        </div>
            <div>
                <nav className="sm:hidden" aria-label="Back">
                    <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                        <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        Back
                    </a>
                </nav>
                <nav className="hidden sm:flex" aria-label="Breadcrumb" >
                    <ol role="list" className="flex items-center space-x-4">
                {props.breadcrumbs && props.breadcrumbs.map((node,i) => {return (<li key={i}>
                            <div className="flex items-center">
                                {i>0 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
                                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                    {node.label}
                                </a>
                            </div>
                        </li>)})}
                    </ol>
                </nav>

            </div>
        </>
    )
}