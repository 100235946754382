import React, {  useEffect} from 'react';
import Session from 'supertokens-web-js/recipe/session';
import { useNavigate } from 'react-router-dom';
import {LoginThirdParty} from "../service/auth";
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import {userLogin} from "../store/api/user";
import {store} from "../store/store";

async function googleSignInClicked() {
    try {
        const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
            thirdPartyId: "google",

            // This is where Google should redirect the user back after login or error.
            // This URL goes on the Google's dashboard as well.
            frontendRedirectURI: window.location.origin + "/auth/callback/google",
        });

        /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

        // we redirect the user to google for auth.
        window.location.assign(authUrl);
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            console.log(err)

        }
    }
}

// async function googleSignInClicked() {
//
//     // setshowloader
//     try {
//         LoginThirdParty("google").then((res) => {
//             console.log("success",res)
//             //localStorage.setItem('loginType', type)
//             if (res && res.data && res.data.status) {
//                 if (res.data.status === "OK" && res.data.url !== "") {
//                     let urlObj = new URL(res.data.url);
//                     urlObj.searchParams.append("redirect_uri", window.location.origin + "/login");
//                     let url = urlObj.toString();
//                     window.location.replace(url)
//                 }
//             }
//         })
//     } catch (error) {
//         console.log("error",error)
//
//         // setshowloader(false)
//     }
// }



export default function Login() {
    const navigate = useNavigate();

    /** On Page Intialize call function to checking session and load all api datas */
    useEffect(() => {
        //setshowloader(true)
        doesSessionExist()
    }, []);


    async function doesSessionExist() {
        if (await Session.doesSessionExist()) {
            console.log("doesSessionExist true")
             let user = await userLogin()
            if (user && (user.email.endsWith("mooonshoot.inc") || user.email.endsWith("carrousela.com"))) {
                navigate("/home/agencies")
            }
        } else {
            console.log("doesSessionExist false")
        }
    }

    return (
        <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center archivo">
            <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center items-center flex-1">
                <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                    <div>
                        <img src="https://app.moonshoot.inc/static/media/logo-title.77c35664c58f5ba142ce239afb558aa1.svg" className="w-64 mx-auto" />
                    </div>
                    <div className="mt-12 flex flex-col items-center">
                        <h1 className="text-2xl xl:text-3xl font-bold">
                            Admin Sign In
                        </h1>
                        <div className="w-full flex-1 mt-8">
                            <div className="flex flex-col items-center ">
                                <button onClick={googleSignInClicked}
                                    className="bg-[#B487FF] w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
                                    <div className="bg-white p-2 rounded-full">
                                        <svg className="w-4" viewBox="0 0 533.5 544.3">
                                            <path
                                                d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                                                fill="#4285f4" />
                                            <path
                                                d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                                                fill="#34a853" />
                                            <path
                                                d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                                                fill="#fbbc04" />
                                            <path
                                                d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                                                fill="#ea4335" />
                                        </svg>
                                    </div>
                                    <span className="ml-4">
                                Sign Up with Google
                            </span>
                                </button>

                            </div>

                            <div className="my-12 border-b text-center">
                                <div
                                    className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                                    Or sign up with e-mail
                                </div>
                            </div>

                            <div className="mx-auto max-w-xs">
                                <input
                                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                    type="email" placeholder="Email" />
                                <input
                                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                                    type="password" placeholder="Password" />
                                <button
                                    className="mt-5 tracking-wide font-semibold bg-[#B487FF] w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ">
                                    <span className="ml-3">
                                Sign In
                            </span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
