
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel, getFilteredRowModel, getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {useMemo, useReducer, useState} from "react";
import {useGetAgencyQuery} from "../../store/api/agency";
import {useGetUserQuery} from "../../store/api/user";
import DefaultAvatar from "../assets/svg/default-avatar.svg";
import Success from "../success";
import Heading from "../heading";
import Modal from "../modal";
import NewClientUser from "../client/new-client-user";



const columnHelper = createColumnHelper()

const columns = [
   columnHelper.accessor(row => row.lastName, {
        id: 'name',
        cell: ({ row }) => {

            return (<div className="flex items-center">
                <div className="h-11 w-11 flex-shrink-0">
                    {row.original.profile_avatar_url?
                        <img className="h-11 w-11 rounded-full" src={row.original.profile_avatar_url} alt="" />:
                        <img className="h-11 w-11 rounded-full" src={DefaultAvatar} alt="" />
                    }
                </div>
                <div className="ml-4">
                    <div className="font-medium text-gray-900">{row.original.first_name + " " + row.original.last_name}</div>
                    <div className="mt-1 text-gray-500">{row.original.email}</div>
                </div>
            </div>);

        },
        header: () => <span>Name</span>,
    }),
    columnHelper.accessor('created_at', {
        header: () => 'Created',
        cell: info => info.renderValue(),
    }),
]

export default function Clients() {
    const { data: clientsData, error: clientsError, isLoading: clientsIsLoading } = useGetUserQuery({"filter":{
        "role":["client"]
    } });
    const [inviteUser, setInviteUser] = useState(false);


    const rerender = useReducer(() => ({}), {})[1]

    let data = []
    if (!clientsIsLoading && clientsData && clientsData.users) {
        data = [...clientsData.users].sort((a, b) => {
            // Convert date strings to Date objects
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            // Compare dates to sort in descending order
            return dateB - dateA;
        });
    }

    const table = useReactTable({
        data: useMemo(() => data, [clientsIsLoading] ),
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    })


    return (

        <div className="p-2">
            <div className="px-4 sm:px-6 lg:px-8">
            {/*{showSuccess && <Success title="New agency saved!"/>}*/}
            <header className="sticky top-0 bg-white">
                <Heading title="Clients" breadcrumbs={[{label:'Client'},{label:'List'}]}/>
            </header>
            <div className="flex justify-end gap-4">
                <button type="button" onClick={() => setInviteUser(true)}
                        className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Invite Client
                </button>
            </div>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0" key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </tfoot>
            </table>
            <div className="h-4" />
            <Modal title="Client Invite" open={inviteUser} onClose={() => setInviteUser(null)}><NewClientUser onClose={() => setInviteUser(null)}/></Modal>
        </div>
    )
}
