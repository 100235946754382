import { configureStore } from '@reduxjs/toolkit';
import { agencyApi } from './api/agency';
import { userApi } from './api/user';

export const store = configureStore({
  reducer:{

    [userApi.reducerPath]: userApi.reducer,
    [agencyApi.reducerPath]: agencyApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(agencyApi.middleware).concat(userApi.middleware),
});