

import {Dialog} from "@headlessui/react";

export default function Modal({ title ,open, onClose, children ,url}) {

    const openModalInNewTab = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <>
            {open ?
                <Dialog
                    open={true}
                    onClose={() => onClose()}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-gray-300 bg-opacity-30 backdrop-filter backdrop-blur" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center p-64">
                            {/* The actual dialog panel  */}
                            <Dialog.Panel className="bg-white">
                                <Dialog.Title>
                                    <div  className="flex justify-between">
                                        <div className="text-[64px] pl-[64px] pt-16">{title}</div>
                                        <div className="p-4">
                                            {url && <button
                                                className="p-1 ml-auto bg-transparent border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                onClick={() => openModalInNewTab()}
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5 7.5L17.5 2.5M17.5 2.5H12.5M17.5 2.5L10.8333 9.16667M8.33333 4.16667H6.5C5.09987 4.16667 4.3998 4.16667 3.86502 4.43915C3.39462 4.67883 3.01217 5.06129 2.77248 5.53169C2.5 6.06647 2.5 6.76654 2.5 8.16667V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H11.8333C13.2335 17.5 13.9335 17.5 14.4683 17.2275C14.9387 16.9878 15.3212 16.6054 15.5608 16.135C15.8333 15.6002 15.8333 14.9001 15.8333 13.5V11.6667" stroke="#000000" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>


                                            </button>}
                                            <button
                                                className="p-1  bg-transparent border-0  text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                onClick={() => onClose()}
                                            ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.2782 15.2738C15.178 15.3739 15.062 15.4399 14.93 15.4717C14.7935 15.5036 14.6615 15.5036 14.5341 15.4717C14.4022 15.4399 14.2884 15.3762 14.1928 15.2806L9.99488 11.0827L5.80375 15.2806C5.70819 15.3762 5.59443 15.4399 5.46246 15.4717C5.33049 15.5036 5.19852 15.5036 5.06655 15.4717C4.93458 15.4399 4.81854 15.3739 4.71843 15.2738C4.62287 15.1782 4.55916 15.0644 4.5273 14.9325C4.49545 14.8005 4.49545 14.6708 4.5273 14.5434C4.55916 14.4114 4.62287 14.2954 4.71843 14.1953L8.90956 9.99733L4.71843 5.8062C4.62287 5.70609 4.55916 5.59232 4.5273 5.4649C4.4909 5.33294 4.4909 5.20097 4.5273 5.069C4.55916 4.93703 4.62287 4.82327 4.71843 4.7277C4.82309 4.62759 4.94141 4.5616 5.07338 4.52975C5.2008 4.4979 5.33049 4.4979 5.46246 4.52975C5.59443 4.55705 5.70819 4.62076 5.80375 4.72088L9.99488 8.91883L14.1928 4.72088C14.2884 4.62531 14.4022 4.5616 14.5341 4.52975C14.6661 4.4979 14.7981 4.4979 14.93 4.52975C15.062 4.5616 15.178 4.62759 15.2782 4.7277C15.3737 4.82327 15.4374 4.93703 15.4693 5.069C15.5011 5.20097 15.5011 5.33294 15.4693 5.4649C15.4374 5.59687 15.3737 5.70836 15.2782 5.79937L11.087 9.99733L15.2782 14.2021C15.3737 14.2931 15.4397 14.4046 15.4761 14.5366C15.508 14.6685 15.508 14.8005 15.4761 14.9325C15.4443 15.0644 15.3783 15.1782 15.2782 15.2738Z" fill="#333333"/>
                                            </svg>
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Title>
                                {children}
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog> : null}
        </>)
}