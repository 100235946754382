import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import {Provider} from "react-redux";
import {store} from "./store/store";

console.log("env:", process.env.REACT_APP_BASE_API_URL)
SuperTokens.init({
    appInfo: {
        appName: "Moonshoot Admin",
        apiDomain: "https://api.moonshoot.inc/",///process.env.REACT_APP_BASE_API_URL,
        websiteDomain: window.location.origin,
        apiBasePath: "/auth",
        websiteBasePath: "/login"
    },
    recipeList: [
        ThirdPartyEmailPassword.init(),
        Session.init()
    ]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <React.StrictMode>
                <Provider store={store}>
                  <App/>
               </Provider>
        </React.StrictMode>

    );


