import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {useNewAgencyMutation, useUpdateAgencyMutation} from "../../store/api/agency";

export default function UpdateAgencyFactor({agency,onClose,onSave}) {
    const [ updateAgency] = useUpdateAgencyMutation();
    const [ factor, setFactor] = useState(agency.factor?agency.factor*100:0);

    function onAgencyFactorSave() {
        updateAgency({
            "agency_id": agency.agency_id,
            "factor": factor/100
        })
        onClose()
    }

    return (
        <div className="p-8">
            <div className="space-y-12">

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Update Agency Factor</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">project matching results for '{agency.name}' will be factored</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Factor
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="first-name"
                                    onChange={(event) => setFactor(event.target.value)}
                                    value={factor}
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div onClick={() => {setFactor(0)}}
                                className="text-indigo-500 mt-2 hover:text-indigo-700 cursor-pointer"
                            >
                                Reset
                            </div>
                        </div>

                    </div>


                </div>

            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" onClick={onClose} className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    onClick={onAgencyFactorSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>

            </div>
        </div>
    )
}
