import {apiService} from "../utils/http";



export const LoginThirdParty = (type) => {
    try {
        let host = process.env.REACT_APP_BASE_API_URL
        if (!host) {
            host = "https://api.moonshoot.inc/"
        }
        return apiService.get({
            url: host + "auth/authorisationurl?thirdPartyId=" + type,
            headers: { rid: "thirdpartyemailpassword" },
            data: ""
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}


