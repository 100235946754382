import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import './App.css';
import React from 'react';

import Home from "./view/home";
import Login from './view/login'
import Agencies from "./view/agency/agencies";
import GoogleLogin from "./service/google-auth-callback";
import Clients from "./view/client/clients";
import Talents from "./view/talent/talents";


function App() {
    return (
            <Router>
                <Routes>
                    <Route index element={<Login />} />
                    <Route path="/auth/callback/google" element={<GoogleLogin/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/home" element={<Home/>}>
                        <Route path="agencies" element={<Agencies/>}/>
                        <Route path="clients" element={<Clients/>}/>
                        <Route path="talents" element={<Talents/>}/>
                    </Route>
                </Routes>
            </Router>
    );
}

export default App;
