// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@import url(./view/assets/fonts/fonts.css){
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 400;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EAIE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,8BAA8B;EAC9B;AAXwC","sourcesContent":["@import url(./view/assets/fonts/fonts.css);\n\n\n{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Archivo\";\n  font-size: 14px;\n  font-weight: 400;\n  -webkit-text-size-adjust: none;\n  -webkit-font-smoothing: subpixel-antialiased;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
